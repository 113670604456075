import React from "react"
import PageLayout from "./PageLayout"
import OpenWork from "./OpenWork"
import { graphql } from "gatsby"

export default function OpenWorkTemplate({ data, pathContext }) {
  return (
    <PageLayout>
      <OpenWork data={data.strapi.works[0]} context={pathContext} />
    </PageLayout>  
  )
}

// FIXME: Netlify warning: Query takes too long

export const openWorkQuery = graphql`
  query OpenWorkQuery($slug: String!) {
    strapi {
      works(where: { work_showcase: { slug: $slug } }) {
        id
        websiteLink
        workName
        coverImage {
          url
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        coverImageMobile {
          url
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        coverMedia {
          url
        }
        categories {
          category
        }
        work_showcase {
          id
          slug
          title
          showcaseImage {
            url
            imageFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        content {
          ... on Strapi_ComponentLayoutIntroBlock {
            id
            title
            description
            introImage {
              url
              imageFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            introMedia {
              url
            }
          }
          ... on Strapi_ComponentLayoutTextBlock {
            id
            title
            description
            textType
          }
          ... on Strapi_ComponentLayoutImage {
            id
            imageType
            imageCaption
            imageDesktop {
              url
              imageFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            imageMobile {
              url
              imageFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          ... on Strapi_ComponentLayoutVideo {
            id
            videoType
            videoCaption
            video {
              url
            }
            videoPosterImage {
              url
              imageFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          ... on Strapi_ComponentLayoutVideoEmbed {
            id
            videoSrc
            videoTitle
          }
        }
      }
    }
  }
`