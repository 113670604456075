import React from "react"
import styled from "styled-components/macro"
import { rem } from "polished"
import { SplitLayout, breakpoint } from "core/layout"
import { Image, SmartVideo, Heading, Text } from "elements"

const Figure = styled.div`
  > * {
    margin: 0
  }

  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};

  ${breakpoint.tablet`
  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};
  `};

  ${breakpoint.laptop`
  margin-top: ${rem(80)};
  margin-bottom: ${rem(80)};
  `};
`

export default function ContentBlock(props) {
  let {
    textType,
    title,
    description,
    imageType,
    imageDesktop,
    imageMobile,
    imageCaption,
    videoType,
    video,
    videoPosterImage,
    videoCaption,
  } = props

  // FIXME: for bloki render procesā būtiski ietekmē performance
  // un pieļauju, ka pamatā nav nepieciešami.

  // Comment: aizpilda imageMobile ar imageDesktop, ja imageMobile ir tukšs
  if (imageDesktop && imageMobile.length !== imageDesktop.length) {
    for (let i = 0; i < imageDesktop.length; i++) {
      imageMobile.push(imageDesktop[i])
    }
  }

  let content = []

  // Comment: saturs netiek izvadīts, kamēr nav zigZagEnd
  if (
    imageType === "zigZag" ||
    videoType === "zigZag" ||
    textType === "zigZag"
  ) {
    if (imageType) {
      for (let i = 0; i < imageDesktop.length; i++) {
        content.push({
          desktop: imageDesktop[i],
          mobile: imageMobile[i],
          caption: imageCaption,
        })
      }
    }
    if (videoType) {
      for (let i = 0; i < video.length; i++) {
        content.push({
          video: video[i],
          poster: videoPosterImage[i],
          caption: videoCaption,
        })
      }
    }
    if (textType) {
      title && content.push({ title: title })
      description && content.push({ text: description })
    }
    return null
  }

  // Comment: zigZagEnd
  if (imageType) {
    for (let i = 0; i < imageDesktop.length; i++) {
      content.push({
        desktop: imageDesktop[i],
        mobile: imageMobile[i],
        caption: imageCaption,
      })
    }
  }

  if (videoType) {
    for (let i = 0; i < video.length; i++) {
      content.push({
        video: video[i],
        poster: videoPosterImage[i],
        caption: videoCaption,
      })
    }
  }

  if (textType) {
    title && content.push({ title: title })
    description && content.push({ text: description })
  }

  const leftCol = content.filter((_, i) => i % 2 === 0)
  const rightCol = content.filter((_, i) => i % 2 === 1)

  return (
    <Figure>
      <SplitLayout
        left={() =>
          leftCol.map((props, i) => {
            return <Content key={`content_${i}`} props={props} ratio={3 / 4} />
          })
        }
        right={() =>
          rightCol.map((props, i) => {
            return <Content key={`content_${i}`} props={props} ratio={3 / 4} />
          })
        }
      />
    </Figure>
  )
}

function Content(props, ratio) {
  const { title, text, desktop, mobile, caption, video, poster } = props
  return (
    <>
      {title && <Heading>{title}</Heading>}
      {text && <Text>{text}</Text>}
      {(desktop || mobile) && (
        <Figure>
          <Image
            imageDesktop={desktop}
            imageMobile={mobile}
            caption={caption}
            ratio={ratio}
          />
        </Figure>
      )}
      {video && (
        <Figure>
          <SmartVideo video={video} poster={poster} caption={caption} />
        </Figure>
      )}
    </>
  )
}
