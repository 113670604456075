import React from "react"
import { SplitLayout } from "core/layout"
import { Heading, Text } from "elements"

export default function TextBlock(props) {
  const { title, text, text_raw } = props
  // Comment: text_raw nav iestrādāts Strapi
  return (
    <SplitLayout
      left={() => (
        <>
          {title && <Heading>{title}</Heading>}
        </>
      )}
      right={() => (
        <>
          {text && <Text>{text}</Text>}
          {text_raw && <Text raw={() => text_raw} />}
        </>
      )}
      removeChildMargins
    />
  )
}
