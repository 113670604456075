import React from "react"
import styled from "styled-components/macro"
import { rem, transparentize } from "polished"
import { motion /* , useSpring, useTransform  */ } from "framer-motion"
import { AspectRatio, breakpoint, fullWidthImgSizes } from "core/layout"
import { /* VideoOverlay */ SmartVideo, Image } from "elements"

const Header = styled(motion.header)`
  /* background-color: ${({ accentColor }) =>
    transparentize(0.8, accentColor)}; */
  margin-bottom: ${rem(40)};
  overflow: hidden;

  /* AspectRatio > RatioEnforcer */
  > div > div {
    padding-top: calc(100vh - 80px); /* 80px Header height */

    /* FIXME: Tikai tā iemesla dēļ, ka admin laukam
    work::CoverImage nav mobilās proporcijas attēla */
    figure,
    figure > div,
    figure > div > div {
      height: 100%;
    }
  }

  ${breakpoint.tablet`
    margin-bottom: ${rem(120)};

    > div > div {
      padding-top: ${(5 / 9) * 100}%;
    }
  `};

  ${breakpoint.laptop`
    margin-bottom: ${rem(160)};

    > div {
      &, img {
        max-height: calc(100vh - 120px);
      }
    }
  `};
`

export default function CoverBlock({
  imageMobile,
  image,
  video,
  embeddedVideo,
}) {
  // TODO: Image transition on mouse move
  // Comment: EmbeddedVideo pievienošana Strapi pusē nav iestrādāta
  return (
    <Header>
      <AspectRatio ratio={5 / 9}>
        {embeddedVideo || video ? (
          <SmartVideo
            video={embeddedVideo || video}
            style={{ height: "100%" }}
          />
        ) : (
          <>
            {image && (
              <Image
                imageMobile={imageMobile}
                imageDesktop={image}
                style={{ margin: 0 }}
                sizes={fullWidthImgSizes}
                priority
              />
            )}
          </>
        )}
      </AspectRatio>
    </Header>
  )
}
