import React from "react"
import styled from "styled-components/macro"
import { rem } from "polished"
import { SplitLayout, breakpoint } from "core/layout"
import { Image, SmartVideo, IFrame } from "elements"

const MarginBox = styled.div`
  margin-top: ${rem(20)};

  ${breakpoint.tablet`
    margin-top: ${rem(40)};
  `};

  ${breakpoint.laptop`
    margin-top: ${rem(80)};
  `};
`

export default function MediaGridBlock(props) {
  let {
    imageDesktop,
    imageMobile,
    imageType,
    imageCaption,
    video,
    videoPosterImage,
    videoType,
    videoCaption,
    videoSrc,
    videoTitle,
  } = props
  let { leftCol, rightCol } = {}

  // FIXME: for bloki render procesā būtiski ietekmē performance
  // un pieļauju, ka pamatā nav nepieciešami.

  // Aizpilda imageMobile ar imageDesktop, ja imageMobile ir tukšs
  if (imageDesktop && imageMobile.length !== imageDesktop.length) {
    for (let i = 0; i < imageDesktop.length; i++) {
      imageMobile.push(imageDesktop[i])
    }
  }

  let source = []

  // saturs netiek izvadīts, kamēr nav splitLayoutEnd
  if (imageType === "splitLayout" || videoType === "splitLayout") {
    if (imageDesktop) {
      for (let i = 0; i < imageDesktop.length; i++) {
        source.push({
          desktop: imageDesktop[i],
          mobile: imageMobile[i],
          caption: imageCaption,
        })
      }
    }
    if (video) {
      for (let i = 0; i < video.length; i++) {
        source.push({
          video: video[i],
          poster: videoPosterImage[i],
          caption: videoCaption,
        })
      }
    }
    return null
  }

  if (imageDesktop) {
    for (let i = 0; i < imageDesktop.length; i++) {
      source.push({
        desktop: imageDesktop[i],
        mobile: imageMobile[i],
        caption: imageCaption,
      })
    }
  }

  if (video) {
    for (let i = 0; i < video.length; i++) {
      source.push({
        video: video[i],
        poster: videoPosterImage[i],
        caption: videoCaption,
      })
    }
  }

  if (videoSrc) {
    source.push({ videoSrc: videoSrc, title: videoTitle })
  }

  if (
    imageType === "leftSide" ||
    videoType === "leftSide" ||
    imageType === "rightSide" ||
    videoType === "rightSide"
  ) {
    leftCol = source.slice()
  } else {
    leftCol = source.filter((_, i) => i % 2 === 0)
    rightCol = source.filter((_, i) => i % 2 === 1)
  }

  // Vairāki faili
  if (rightCol && rightCol.length) {
    return (
      <SplitLayout
        left={() =>
          leftCol.map((props, i) => {
            return <Media key={`media_${i}`} {...props} ratio={3 / 4} />
          })
        }
        right={() =>
          rightCol.map((props, i) => {
            return <Media key={`media${i}`} {...props} ratio={3 / 4} />
          })
        }
      />
    )
  }

  // Viens fails un left/right side
  return leftCol.map((props, i) => {
    if (imageType === "leftSide" || videoType === "leftSide") {
      return (
        <SplitLayout
          key={`layout_${i}`}
          left={() => <Media {...props} ratio={3 / 4} />}
          right={() => <></>}
        />
      )
    } else if (imageType === "rightSide" || videoType === "rightSide") {
      return (
        <SplitLayout
          key={`layout_${i}`}
          left={() => <></>}
          right={() => <Media {...props} ratio={3 / 4} />}
        />
      )
    } else {
      return <Media key={`media_${i}`} {...props} ratio={9 / 16} />
    }
  })
}

function Media(data) {
  return (
    <>
      {(data.desktop || data.mobile) && (
        <Image
          imageDesktop={data.desktop}
          imageMobile={data.mobile}
          caption={data.caption}
          ratio={data.ratio}
        />
      )}
      {data.video && (
        <MarginBox>
          <SmartVideo
            video={data.video}
            poster={data.poster}
            caption={data.caption}
          />
        </MarginBox>
      )}
      {data.videoSrc && (
        <MarginBox>
          <IFrame title={data.title} src={data.videoSrc} />
        </MarginBox>
      )}
    </>
  )
}