import React from "react"
import styled from "styled-components/macro"
import { rem } from "polished"
import { SplitLayout, breakpoint } from "core/layout"
import { Heading, Text } from "elements"

const Meta = styled.div`
  margin-top: ${rem(40)};

  > *:nth-child(2) {
    margin-top: ${rem(20)};
  }

  ${breakpoint.tablet`
    margin-top: 0;
  `};

  ${breakpoint.laptop`
    > *:nth-child(2) {
        margin-top: ${rem(40)};
    }
  `};
`

export default function HeadingBlock({ title, scribble, client, category }) {
  return (
    <SplitLayout
      flex="2 1 auto/1 0 auto"
      left={() => (
        <Heading primary scribble={scribble}>
          {title}
        </Heading>
      )}
      right={() => (
        <Meta>
          <Text as="span">{client}</Text>
          <Text as="span">{category}</Text>
        </Meta>
      )}
      removeChildMargins
    />
  )
}
