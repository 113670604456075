import React from "react"
import styled from "styled-components/macro"
import { rem } from "polished"
import { PageContainer, breakpoint } from "core/layout"
import { PageTransition } from "core/components"
import { useAnalytics } from "core/hooks"
import { color } from "core/theme"
import { Heading, ScrollToTop } from "elements"
import { PrevNext } from "modules"
import {
  CoverBlock,
  HeadingBlock,
  IntroBlock,
  TextBlock,
  MediaGridBlock,
  ContentBlock,
} from "modules/contentBlocks"

const Article = styled.article`
  overflow-x: hidden;

  PageContainer > header + div {
    margin-bottom: ${rem(80)};
  }

  section:not(:first-child) {
    margin-top: ${rem(80)};

    ${breakpoint.tablet`
      margin-top: ${rem(120)};
    `};

    ${breakpoint.laptop`
      margin-top: ${rem(160)};
    `};
  }

  ${breakpoint.tablet`
    > header + div {
      margin-bottom: ${rem(120)};
    }
  `};

  ${breakpoint.laptop`
    > header + div {
      margin-bottom: ${rem(160)};
    }
  `};
`

const WebsiteUrl = styled.div`
  background-color: ${color.brand.black};
  height: 50vw;
  max-height: 400px;

  > a {
    display: flex;
    height: 100%;
    text-decoration: none;

    > * {
      margin: auto;
    }

    > div {
      border-bottom: 2px solid transparent;
    }

    span {
      color: ${color.white};
    }

    &:hover {
      > div {
        border-bottom: 2px solid ${color.brand.red};
      }
    }
  }
`
const TextBlockWrap = styled.div`
  ${breakpoint.tablet`{
    margin-top: ${rem(120)};
  }
  `};

  ${breakpoint.laptop`{
    margin-top: ${rem(160)};
  }
  `};
`

export default function OpenWork({ data, context }) {
  useAnalytics({
    title: `open-work`,
    path: `/works/${context.slug}`,
  })

  const coverProps = {}
  if (data.coverMedia) {
    coverProps.video = { url: data.coverMedia.url }
  }
  if (data.coverImageMobile) {
    coverProps.imageMobile = data.coverImageMobile
  }
  if (data.coverImage) {
    coverProps.image = data.coverImage
  } else if (data.work_showcase) {
    coverProps.image = data.work_showcase.showcaseImage
  }

  return (
    <PageTransition>
      <Article>
        <CoverBlock {...coverProps} />

        <PageContainer>
          <HeadingBlock
            title={data.work_showcase.title}
            client={data.workName}
            category={data.categories?.[0]?.category}
          />

          {/* TODO: Atbilstošo bloku (modules/contentBlocks)
              renderēšana bez liekas starpniecības */}
          {data.content.map(buildBlock)}
        </PageContainer>

        {data.websiteLink && (
          <WebsiteUrl>
            <a
              href={`https://${data.websiteLink}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Heading as="span" style={{ margin: 0 }} dot="nodot">
                {data.websiteLink}
              </Heading>
            </a>
          </WebsiteUrl>
        )}
      </Article>

      <PrevNext current={context.slug} works={context.showcases} />
      <ScrollToTop currentId={data.id} />
    </PageTransition>
  )
}

function buildBlock(content, index) {
  switch (content.__typename) {
    case "Strapi_ComponentLayoutIntroBlock":
      return buildIntroBlock({
        content,
        key: `${content.__typename}_${index}`,
      })
    case "Strapi_ComponentLayoutTextBlock":
      return buildTextBlock({
        content,
        key: `${content.__typename}_${index}`,
      })
    case "Strapi_ComponentLayoutImage":
    case "Strapi_ComponentLayoutVideo":
    case "Strapi_ComponentLayoutVideoEmbed":
      return buildMediaGridBlock({
        content,
        key: `${content.__typename}_${index}`,
      })
    default:
      return null
  }
}

function buildIntroBlock({ key, content }) {
  const { title, description, introImage, introMedia } = content
  return (
    <TextBlockWrap key={key}>
      <IntroBlock
        title={title}
        text={description}
        image={introImage ? { ...introImage } : null}
        media={introMedia ? { ...introMedia } : null}
      />
    </TextBlockWrap>
  )
}

function buildTextBlock({ key, content }) {
  const { title, description, textType } = content

  if (textType && textType !== "default") {
    return <ContentBlock key={key} {...content} />
  }

  return (
    <TextBlockWrap key={key}>
      <TextBlock title={title} text={description} />
    </TextBlockWrap>
  )
}

function buildMediaGridBlock({ key, content }) {
  const { imageType, videoType } = content

  if (
    imageType === "zigZag" ||
    videoType === "zigZag" ||
    imageType === "zigZagEnd" ||
    videoType === "zigZagEnd"
  ) {
    return <ContentBlock key={key} {...content} />
  }

  return <MediaGridBlock key={key} {...content} />
}
