import React from "react"
import { breakpoint, SplitLayout, twoThirdsImgSizes } from "core/layout"
import { Heading, Text, SmartVideo } from "elements"
import Img from "gatsby-image"
import { rem } from "polished"
import styled from "styled-components/macro"

const TextWrap = styled.div`
  ${breakpoint.tablet`{
    margin-bottom: ${rem(60)};
  }
  `};

  ${breakpoint.laptop`{
    margin-bottom: ${rem(80)};
  }
  `};
`

export default function IntroBlock({ title, text, image, media}) {
  return (
    <SplitLayout
      flex="1/2"
      left={() => <Heading>{title}</Heading>}
      right={() => (
        <>
          <TextWrap>
            <Text lead>{text}</Text>
          </TextWrap>
          {image && (
            <Img
              fluid={image.imageFile?.childImageSharp?.fluid}
              className={twoThirdsImgSizes}
              ratio={3 / 4}
              key={image.url}
            />
          )}
          {media && <SmartVideo video={media} />}
        </>
      )}
      removeChildMargins
    />
  )
}
